.main {
  display: flex;
  border-radius: 5px;
  gap: 5px;
  padding: 5px;
  align-items: center;
  background-color: #f5f5f5;
}

.label {
  font-size: 32px;
  font-weight: bold;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 50px;
  color: #050505;
}

.max {
  height: 35px;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
  background-color: #d3d3d3;
}
.value {
  height: 100%;
  background-color: #0566fa;

  transition: 0.35s;
}
