.main {
  height: 100vh;
  display: flex;
  align-items: center;
}

.battleTriggerButton {
  border: none;
  outline: none;
  padding: 15px;
  font-size: 32px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 3px #808080;
}
